import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IdentityService} from '../../../business/users/core/identity.service';
import {User} from '../../../infrastructure/auth/models/user';
import {NavigationPanelService} from '../../../pages/common/navigation-panel';
import {AsyncPipe, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {LocalizationPipe} from "../../../infrastructure/localization/localization.pipe";
import {FavoritesHeaderComponent} from "../favorites-header/component";
import {AvatarComponent} from "../../../pages/common/avatar/component";
import {RouterLink} from "@angular/router";
import {MatButton} from "@angular/material/button";
import {MatRipple} from "@angular/material/core";
import {ChatHeaderComponent} from "../chat-header/component";
import {NotificationsHeaderComponent} from "../../../pages/common/notifications-header/component";
import {GoogleAnalyticsEventDirective} from "../../../infrastructure/google-analytics/google-analytics-event.directive";

@Component({
    selector: 'nn-header-auth-state',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AsyncPipe,
        MatIcon,
        LocalizationPipe,
        FavoritesHeaderComponent,
        ChatHeaderComponent,
        NotificationsHeaderComponent,
        AvatarComponent,
        RouterLink,
        MatButton,
        MatRipple,
        NgIf,
        GoogleAnalyticsEventDirective
    ]
})
export class AuthHeaderComponent {
    public readonly identityService = inject(IdentityService);

    private readonly navigationPanelService = inject(NavigationPanelService);

    public onUserClick(user: User): void {
        if (!user.isLocked) this.navigationPanelService.open();
    }
}
