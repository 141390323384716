import {inject} from "@angular/core";
import {CanActivateFn, createUrlTreeFromSnapshot} from "@angular/router";
import {UserTrustStatusService} from "../../../business/users/core/user-trust-status.service";
import {map} from "rxjs/operators";

export function untrustedUserGuard(): CanActivateFn {
    return route => {
        const trustStatusService = inject(UserTrustStatusService);
        return trustStatusService.checkTrustStatus().pipe(map(isTrusted => isTrusted || createUrlTreeFromSnapshot(route, ["/"])));
    }
}
