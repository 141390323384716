import {Injectable} from '@angular/core';
import {FcmClient} from './FcmClient';
import {from, Observable, of} from 'rxjs';
import {IdentityService} from '../../business/users/core/identity.service';
import {catchError, distinctUntilChanged, filter, map, switchMap} from 'rxjs/operators';
import {SentryWrapper} from '../sentry/sentry-wrapper.service';
import {UserDeviceTokensService} from '../../business/notifications/services/user-device-tokens.service';

@Injectable()
export class FcmService {
    constructor(
        private readonly fcmClient: FcmClient,
        private readonly identityService: IdentityService,
        private readonly tokensService: UserDeviceTokensService,
        private readonly sentryWrapper: SentryWrapper,
    ) {
    }

    public init() {
        this.fcmClient.fcmInitialized$.pipe(
            filter(isInitialized => isInitialized),
            switchMap(() => this.identityService.stateChanges),
            map(state => state.user?.Id),
            distinctUntilChanged(),
            filter(id => !!id),
            switchMap(() => this.requestPermission()),
            filter(permissionResult => permissionResult),
            switchMap(() => this.sendFcmTokenToBackend()),
        ).subscribe();
    }

    private sendFcmTokenToBackend(): Observable<void> {
        return this.fcmClient.getToken().pipe(
            filter(token => !!token),
            switchMap(token => this.tokensService.addUserDeviceToken(token)),
            catchError((err: unknown) => {
                this.sentryWrapper.captureException(err);
                return of(null);
            }),
        );
    }

    private requestPermission(): Observable<boolean> {
        return from(Notification.requestPermission()).pipe(
            map(permissionResult => permissionResult === 'granted'),
            catchError(() => of(false)),
        );
    }
}
