import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IdentityService} from '../../../business/users/core/identity.service';
import {Observable} from 'rxjs';
import {UserRoleType} from '../../../business/catalog/core/api';
import {MatDialog} from '@angular/material/dialog';
import {map} from 'rxjs/operators';
import {CatalogType} from '../../../pages/catalog/routing/CatalogType';
import {CatalogMenuService} from '../services/catalog-menu.service';
import {HeaderDesktopMenuService} from '../services/header-desktop-menu.service';
import {HeaderSearchFiltersService} from '../services/header-search-filters.service';
import {LocalizationPipe} from "../../../infrastructure/localization/localization.pipe";
import {DesktopMenuComponent} from "../desktop-menu/component";
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {AuthHeaderComponent} from "../auth-header/component";
import {RouterLink} from "@angular/router";
import {MatButton} from "@angular/material/button";
import {MatRipple} from "@angular/material/core";
import {CountryDomainDirective} from "../../../infrastructure/country-domain/country-domain.directive";

@Component({
    selector: 'nn-common-header',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        LocalizationPipe,
        DesktopMenuComponent,
        AsyncPipe,
        NgClass,
        MatIcon,
        AuthHeaderComponent,
        RouterLink,
        MatButton,
        MatRipple,
        NgIf,
        CountryDomainDirective
    ]
})
export class HeaderComponent implements OnInit {
    @Output()
    public mobileMenuToggle: EventEmitter<void> = new EventEmitter<void>();
    public userRoleType = UserRoleType;

    public isNotLocked$: Observable<boolean>;
    public catalogType = CatalogType;
    public CatalogType = CatalogType;

    constructor(
        public readonly identityService: IdentityService,
        private readonly dialog: MatDialog,
        public readonly catalogMenuService: CatalogMenuService,
        public readonly menuService: HeaderDesktopMenuService,
        private readonly headerSearchFiltersService: HeaderSearchFiltersService) {}

    public ngOnInit() {
        this.isNotLocked$ = this.identityService.stateChanges.pipe(
            map((authState) => !(authState.user && authState.user.isLocked)),
        );
    }

    public showFilterDialog() {
        this.headerSearchFiltersService.openDialog();
    }

    public openInterestingMenu(): void {
        this.dialog.closeAll();
        this.menuService.interesting = true;
    }

    public openCatalogMenu(catalogType: CatalogType) {
        this.dialog.closeAll();
        this.menuService.catalog = catalogType;
    }
}
