import {API_BASE_URL} from "../../business/catalog/core/api";
import {APP_CONFIG, AppConfig} from "../app-config/AppConfig";
import {SEO_API_BASE_URL} from "../../business/seo/api/seo-api-client";
import {NOTIFICATIONS_API_BASE_URL} from "../../business/notifications/api/notifications-api-client";
import {CATALOG_SEARCH_API_BASE_URL} from "../../business/catalog/core/api/catalog-search-api-client";
import {DVS_API_BASE_URL} from "../../business/dvs/api/dvs-api-client";
import {GEO_API_BASE_URL} from "../../business/geo/api/geo-api-client";
import {LOCALIZATION_API_BASE_URL} from "../localization/localization-api/localization-api-client";
import {MG_TWILIO_API_BASE_URL} from "../../business/message-gate/core/api/mg-twilio-api-client";
import {AUTH_API_BASE_URL} from "../auth/core/api/auth-api.client";
import {PELIAS_API_BASE_URL} from "../../business/geo/core/pelias/PeliasClient";
import {DADATA_API_KEY} from "../../business/geo/core/dadata/dadata.service";
import {GEOAPIFY_API_KEY} from "../../business/geo/core/us-geoapify/api";
import {makeEnvironmentProviders, Provider} from "@angular/core";

const providers: Provider[] = [
    {
        provide: API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.dataApi,
        deps: [APP_CONFIG],
    },
    {
        provide: SEO_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.seoApi,
        deps: [APP_CONFIG],
    },
    {
        provide: NOTIFICATIONS_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.notificationsApi,
        deps: [APP_CONFIG],
    },
    {
        provide: CATALOG_SEARCH_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.catalogSearchApi,
        deps: [APP_CONFIG],
    },
    {
        provide: DVS_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.dvsApi,
        deps: [APP_CONFIG],
    },
    {
        provide: GEO_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.geoApi,
        deps: [APP_CONFIG],
    },
    {
        provide: LOCALIZATION_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.localizationApi,
        deps: [APP_CONFIG],
    },
    {
        provide: MG_TWILIO_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.twilioApi,
        deps: [APP_CONFIG],
    },
    {
        provide: AUTH_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.authApi,
        deps: [APP_CONFIG],
    },
    {
        provide: PELIAS_API_BASE_URL,
        useFactory: (appConfig: AppConfig) => appConfig.peliasApiEndpoint,
        deps: [APP_CONFIG],
    },
    {
        provide: DADATA_API_KEY,
        useFactory: (appConfig: AppConfig) => appConfig.dadataApiKey,
        deps: [APP_CONFIG],
    },
    {
        provide: GEOAPIFY_API_KEY,
        useFactory: (appConfig: AppConfig) => appConfig.geoapifyApiKey,
        deps: [APP_CONFIG],
    },
]

export function provideApiBaseUrls(){
    return makeEnvironmentProviders(providers);
}
