import {inject, Pipe, PipeTransform} from "@angular/core";
import {Observable} from "rxjs";
import {ChatService} from "../../business/chat/core/messaging/chat.service";
import {distinctUntilChanged, map, startWith} from "rxjs/operators";

@Pipe({
    name: "nnTotalUnreadChatMessagesCount",
    standalone: true,
})
export class TotalUnreadChatMessagesCountPipe implements PipeTransform {
    private readonly chatService = inject(ChatService);

    public transform(defaultValue: number): Observable<number> {
        return this.chatService.unreadMessagesCount$.pipe(
            distinctUntilChanged(),
            map(count => Math.max(count, 0)),
            startWith(defaultValue));
    }
}
