import {Injectable} from '@angular/core';
import {ActivationEnd, Router} from '@angular/router';
import {Observable, ReplaySubject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FooterService {
    private showFooterSubject$: ReplaySubject<boolean>;

    constructor(router: Router) {
        this.showFooterSubject$ = new ReplaySubject<boolean>(1);
        router.events.pipe(
            filter(e => e instanceof ActivationEnd),
            filter((event: ActivationEnd) => event.snapshot.firstChild == null),
            map((event: ActivationEnd) => !event.snapshot.data?.hideFooter),
            tap(show => this.showFooterSubject$.next(show)),
        ).subscribe();
    }

    public get showFooter$(): Observable<boolean> {return this.showFooterSubject$.asObservable();}
}
