import {ChangeDetectionStrategy, Component, Inject, PLATFORM_ID} from '@angular/core';
import {ChatUsersService} from '../../business/chat/core/chat-users.service';
import {Observable, of} from 'rxjs';
import {ChatUsersConnectionTrackingService} from '../../business/chat/core/chat-users-connection-tracking.service';
import {AsyncPipe, isPlatformBrowser, NgIf} from '@angular/common';
import {APP_CONFIG, AppConfig} from "../../infrastructure/app-config/AppConfig";
import {LanguageSelectorComponent} from "./language-selector/component";
import {LocalizationPipe} from "../../infrastructure/localization/localization.pipe";
import {MatIcon} from "@angular/material/icon";
import {AppStoreLinkPipe} from "../../shared/pipes/app-store-link.pipe";
import {RouterLink} from "@angular/router";
import {CountryDomainDirective} from "../../infrastructure/country-domain/country-domain.directive";
import {ScreenModeDirective} from "../../infrastructure/screen-mode/screen-mode.directive";
import {GooglePlayLinkPipe} from "../../shared/pipes/google-play-link/google-play-link.pipe";
import {AuthStateDirective} from "../../infrastructure/auth/directives/auth-state/auth-state.directive";

@Component({
    selector: 'nn-main-footer',
    templateUrl: 'component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        LanguageSelectorComponent,
        LocalizationPipe,
        AsyncPipe,
        MatIcon,
        AppStoreLinkPipe,
        RouterLink,
        CountryDomainDirective,
        ScreenModeDirective,
        NgIf,
        GooglePlayLinkPipe,
        AuthStateDirective,
    ]
})
export class MainFooterComponent {
    public supportAccountOnlineStatus$: Observable<boolean>;
    public readonly currentYear: number = new Date().getFullYear();

    constructor(chatUsersService: ChatUsersService,
                chatUsersConnectionTrackingService: ChatUsersConnectionTrackingService,
                @Inject(PLATFORM_ID) private readonly platformId: object,
                @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
    ) {
        if (!isPlatformBrowser(this.platformId) || !this.appConfig.chatSupportEnabled())
            this.supportAccountOnlineStatus$ = of(true);
        else
            this.supportAccountOnlineStatus$ = chatUsersConnectionTrackingService.getUserOnlineStatus(chatUsersService.supportAccountId);
    }
}
