import {Routes} from '@angular/router';
import {DeactivateGuard} from './infrastructure/auth/guards/deactivate-guard.service';
import {MobileMenuItem} from "./layout/mobile-nav/misc/mobileMenuItem";
import {AuthGuard} from "./infrastructure/auth/guards/auth-guard.service";
import {CatalogType} from "./pages/catalog/routing/CatalogType";
import {getCatalogRoutes} from "./pages/catalog/routing/getCatalogRoutes";
import {WorkType} from "./business/catalog/core/api";
import {
    housekeeperResumeSearchPageConfig
} from "./pages/catalog/search/resume/housekeeper-resume-search/HousekeeperResumeSearchPageConfig";
import {
    nannyResumeSearchPageConfig
} from "./pages/catalog/search/resume/nanny-resume-search/NannyResumeSearchPageConfig";
import {
    nurseResumeSearchPageConfig
} from "./pages/catalog/search/resume/nurse-resume-search/NurseResumeSearchPageConfig";
import {
    petCareResumeSearchPageConfig
} from "./pages/catalog/search/resume/petcare-resume-search/PetCareResumeSearchPageConfig";
import {
    tutorResumeSearchPageConfig
} from "./pages/catalog/search/resume/tutor-resume-search/TutorResumeSearchPageConfig";
import {
    cookResumeSearchPageConfig
} from "./pages/catalog/search/resume/cook-resume-search/cook-resume-search-page.config";
import {
    driverResumeSearchPageConfig
} from "./pages/catalog/search/resume/driver-resume-search/driver-resume-search-page.config";
import {
    housekeeperVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/housekeeper-vacancy-search/HousekeeperVacancySearchPageConfig";
import {
    nannyVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/nanny-vacancy-search/NannyVacancySearchPageConfig";
import {
    nurseVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/nurse-vacancy-search/NurseVacancySearchPageConfig";
import {
    petCareVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/petcare-vacancy-search/PetCareVacancySearchPageConfig";
import {
    tutorVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/tutor-vacancy-search/TutorVacancySearchPageConfig";
import {
    cookVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/cook-vacancy-search/cook-vacancy-search-page.config";
import {
    driverVacancySearchPageConfig
} from "./pages/catalog/search/vacancy/driver-vacancy-search/driver-vacancy-search-page.config";
import {countryDomainGuard} from "./infrastructure/auth/guards/country-domain.guard";
import {COUNTRY_DOMAIN} from "./infrastructure/app-config/AppConfig";
import {RatesToBuyGuard} from "./infrastructure/auth/guards/rates-to-buy.guard";
import {WorkerSectionGuard} from "./infrastructure/auth/guards/worker-section-guard.service";
import {referralsPagesGuard} from "./infrastructure/auth/guards/referrals-pages.guard";
import {agencyGuard} from "./infrastructure/auth/guards/agency.guard";
import {untrustedUserGuard} from "./infrastructure/auth/guards/untrusted-user.guard";

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/+landing/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
        pathMatch: 'full',
    },
    {
        path: 'login',
        data: {mobileMenuItem: MobileMenuItem.Login},
        loadChildren: () => import('./pages/auth/login/routing').then(mod => mod.routes),
    },
    {
        path: 'restore',
        loadChildren: () => import('./pages/auth/restore/routing').then(mod => mod.routes),
    },
    {
        path: 'confirmemail',
        loadChildren: () => import('./pages/auth/confirm-email/routing').then(mod => mod.routes),
    },
    {
        path: 'magic-auth',
        loadChildren: () => import('./pages/auth/magic-auth/routing').then(mod => mod.routes),
    },
    {
        path: 'dashboard',
        redirectTo: '/account',
    },
    {
        path: 'profile',
        redirectTo: '/account/profile',
    },
    {
        path: 'error',
        loadChildren: () => import('./pages/+error/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'buy', loadChildren: () => import('./pages/+buy/routing').then(mod => mod.routes),
        canActivateChild: [AuthGuard, DeactivateGuard],
    },
    {
        path: 'resume-management',
        data: {mobileMenuItem: MobileMenuItem.ResumeManagement},
        loadChildren: () => import('./pages/catalog/management/resume/routing.module').then(mod => mod.routes),
    },
    {
        path: 'notifications',
        loadChildren: () => import('./pages/+notifications/routing').then(mod => mod.routes),
        canActivateChild: [AuthGuard, DeactivateGuard],
    },
    getCatalogRoutes(CatalogType.Resume, WorkType.Housekeeper, housekeeperResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Resume, WorkType.Nanny, nannyResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Resume, WorkType.Nurse, nurseResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Resume, WorkType.PetCare, petCareResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Resume, WorkType.Tutor, tutorResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Resume, WorkType.Cook, cookResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Resume, WorkType.Driver, driverResumeSearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.Housekeeper, housekeeperVacancySearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.Nanny, nannyVacancySearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.Nurse, nurseVacancySearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.PetCare, petCareVacancySearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.Tutor, tutorVacancySearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.Cook, cookVacancySearchPageConfig),
    getCatalogRoutes(CatalogType.Vacancy, WorkType.Driver, driverVacancySearchPageConfig),
    {
        path: 'map',
        loadChildren: () => import('./pages/catalog/search/map/module').then(m => m.SearchMapModule),
    },
    {
        path: 'resume',
        loadChildren: () => import('./pages/catalog/edit/resume/routing.module').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'resume-list',
        loadChildren: () => import('./pages/+resume-list/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'vacancy',
        loadChildren: () => import('./pages/catalog/edit/vacancy/routing.module').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'vacancy-list',
        loadChildren: () => import('./pages/+vacancy-list/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'my-vacancies',
        redirectTo: '/account/vacancy-management',
    },
    {
        path: 'pay-control',
        redirectTo: '/account/orders',
    },
    {
        path: 'receipt', loadChildren: () => import('./pages/+receipt/routing').then(mod => mod.routes),
        canActivateChild: [AuthGuard, DeactivateGuard],
    },
    {
        path: 'settings',
        redirectTo: '/account/preferences',
    },
    {
        path: 'terms-of-services',
        loadChildren: () => import('./pages/+terms/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'unsubscribe',
        loadChildren: () => import('./pages/+unsubscribe/routes').then(mod => mod.routes),
    },
    {
        path: 'help',
        loadChildren: () => import('./pages/+help/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'taxes',
        loadChildren: () => import('./pages/+taxes/routing').then((module) => module.routes),
        canActivateChild: [DeactivateGuard, countryDomainGuard(COUNTRY_DOMAIN.CA)],
    },
    {
        path: 'reviews',
        loadChildren: () => import('./pages/+reviews/routing').then((module) => module.routes),
        canActivateChild: [countryDomainGuard(COUNTRY_DOMAIN.CA)],
    },
    {
        path: 'safety',
        loadChildren: () => import('./pages/+safety-tips/routing').then(module => module.routes),
        canActivateChild: [countryDomainGuard(COUNTRY_DOMAIN.CA)],
    },
    {
        path: 'rates',
        loadChildren: () => import('./pages/+rates/routing').then((module) => module.routes),
        canActivateChild: [countryDomainGuard(COUNTRY_DOMAIN.CA), RatesToBuyGuard],
    },
    {
        path: 'docs',
        loadChildren: () => import('./pages/+docs/routing').then(mod => mod.routes),
        canActivateChild: [DeactivateGuard],
    },
    {
        path: 'guests',
        redirectTo: '/account/guests',
    },
    {
        path: 'about',
        loadChildren: () => import('./pages/+about/routes').then(mod => mod.routes),
    },
    {
        path: 'blog',
        loadChildren: () => import('./pages/+blog/routing').then(mod => mod.Routing),
    },
    {
        path: 'personal-data-agreement',
        loadChildren: () => import('./pages/+personal-data-agreement/routing').then(mod => mod.routes),
        canActivateChild: [countryDomainGuard(COUNTRY_DOMAIN.RU)],
    },
    {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/+privacy-policy/routing').then(mod => mod.routes),
    },
    {
        path: 'recurrent-payments-agreement',
        loadChildren: () => import('./pages/+recurrent-payments-agreement/routing').then(mod => mod.routes),
        canActivateChild: [countryDomainGuard(COUNTRY_DOMAIN.RU)],
    },
    {
        path: 'apps',
        loadChildren: () => import('./pages/+apps/routing').then((mod) => mod.Routing),
        canActivateChild: [countryDomainGuard(COUNTRY_DOMAIN.RU)],
    },
    {
        path: 'worker-data',
        loadChildren: () => import('./pages/+worker-data/routing').then((module) => module.routes),
        canActivateChild: [
            AuthGuard,
            DeactivateGuard,
            WorkerSectionGuard,
        ],
    },
    {
        path: 'chat',
        data: {hideFooter: true, mobileMenuItem: MobileMenuItem.Chat},
        loadChildren: () => import('./pages/+chat/routing').then(mod => mod.routes),
        canActivate: [AuthGuard, DeactivateGuard, untrustedUserGuard()],
    },
    {
        path: 'chat-search',
        data: {hideFooter: true},
        loadChildren: () => import('./pages/+chat-search/routes').then(mod => mod.routes),
        canActivate: [AuthGuard, DeactivateGuard],
    },
    {
        path: 'deactivated-user',
        loadChildren: () => import('./pages/+deactivated/routing').then(mod => mod.routes),
    },
    {
        path: 'register',
        data: {hideFooter: true, mobileMenuItem: MobileMenuItem.Register},
        loadChildren: () => import('./pages/+register/routing').then(mod => mod.routes),
    },
    {path: 'magic-end', loadChildren: () => import('./pages/+magic-end/routing').then(mod => mod.routes)},
    {path: 'account', loadChildren: () => import('./pages/+account/routes').then(mod => mod.routes)},
    {
        path: 'test',
        loadChildren: () => import('./pages/+test/routing').then(module => module.routes),
    },
    {
        path: 'faq',
        loadChildren: () => import('./pages/+faq/routes').then((mod) => mod.routes),
    },
    {
        path: 'favorites',
        data: {hideFooter: true, mobileMenuItem: MobileMenuItem.Favorites},
        loadChildren: () => import('./pages/+favorites-board/routing').then(module => module.routes),
        canActivate: [AuthGuard, DeactivateGuard],
    },
    {
        path: 'relevants',
        loadChildren: () => import('./pages/catalog/relevants/routing.module').then(module => module.routes),
        canActivate: [AuthGuard, DeactivateGuard],
    },
    {path: 'jobsua', loadChildren: () => import('./pages/+jobsua/routing').then(mod => mod.routes)},
    {
        path: 'honest-reviews',
        loadChildren: () => import('./pages/honest-reviews/routing.module').then(module => module.routes),
    },
    {
        path: 'forbidden',
        loadChildren: () => import('./pages/+forbidden/routing').then(module => module.routes),
    },
    {
        path: 'referrals',
        loadChildren: () => import('./pages/referrals/routing').then(module => module.routes),
        canActivate: [AuthGuard, DeactivateGuard, referralsPagesGuard(), agencyGuard(true)],
    },
    {
        path: 'refs',
        loadChildren: () => import('./pages/refs/routing').then(module => module.routes),
        canActivate: [referralsPagesGuard()],
    },
    {
        path: 'mdx',
        loadChildren: () => import('./pages/mdx/routing').then(module => module.routes),
    },
    {path: '**', redirectTo: 'error'},
];
