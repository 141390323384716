import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy} from '@angular/core';
import {NotificationsService} from '../../../business/notifications/services/notifications-service';
import {NotificationsPanelComponent} from '../../../layout/header/notifications-panel/component';
import {MatDialog} from '@angular/material/dialog';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AsyncPipe, NgClass, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatRipple} from "@angular/material/core";

@Component({
    selector: 'nn-notifications-header',
    templateUrl: 'component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        NgClass,
        MatIcon,
        AsyncPipe,
        MatRipple,
        NgIf
    ]
})
export class NotificationsHeaderComponent implements OnDestroy {
    public unreadCount$: Observable<number>;
    public dialogOpened: boolean = false;
    private dialogAfterClosedSubscription: Subscription;

    @Input()
    public viewMode: 'full' | 'short' = 'full';

    constructor(
        public readonly notificationsService: NotificationsService,
        private readonly dialog: MatDialog,
        private readonly changeDetectorRef: ChangeDetectorRef) {
        this.unreadCount$ = notificationsService.state$.pipe(map(state => state.unreadCount));
    }

    public showNotificationsPanel() {
        if (this.dialogOpened)
            return;

        this.dialog.closeAll();

        const dialogRef = this.dialog.open(
            NotificationsPanelComponent,
            {...NotificationsPanelComponent.dialogConfig},
        );

        this.dialogAfterClosedSubscription = dialogRef.afterClosed().subscribe(() => {
            this.dialogOpened = false;
            this.changeDetectorRef.detectChanges();
        });

        this.dialogOpened = true;
    }

    public ngOnDestroy(): void {
        this.dialogAfterClosedSubscription?.unsubscribe();
    }
}
