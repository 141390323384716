import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ChatService} from '../../../business/chat/core/messaging/chat.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {MatIcon} from "@angular/material/icon";
import {AsyncPipe, NgIf} from "@angular/common";
import {LocalizationPipe} from "../../../infrastructure/localization/localization.pipe";
import {MatRipple} from "@angular/material/core";

@Component({
    selector: 'nn-chat-header',
    templateUrl: 'component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        RouterLink,
        MatIcon,
        AsyncPipe,
        LocalizationPipe,
        RouterLinkActive,
        MatRipple,
        NgIf
    ]
})
export class ChatHeaderComponent {
    public messageCount$: Observable<number>;

    constructor(chatService: ChatService) {
        this.messageCount$ = chatService.unreadMessagesCount$.pipe(map(count => count >= 0 ? count : 0));
    }
}
