<ng-container *ngIf="context$ | async as context">
    <div class="nav-header">
        <nn-user-info
            class="nav-header__account"
            [user]="context.user"></nn-user-info>
        <nn-notifications-header
            class="nav-header__button"
            viewMode="short"></nn-notifications-header>
    </div>
    <nn-worker-interesting
        *ngIf="context.user.isWorker"></nn-worker-interesting>
    <nav class="navigation">
        <a
            class="navigation__link"
            routerLink="/account"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|dashboard' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileDashboard"
                    svgIcon="sprite:icon-profileDashboard"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|dashboard" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            *ngIf="context.user.isWorker"
            routerLink="/favorites"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|favorites' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileFavorites2"
                    svgIcon="sprite:icon-profileFavorites2"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|favorites" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            routerLink="/account/guests"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|guests' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileGuest2"
                    svgIcon="sprite:icon-profileGuest2"></mat-icon>
                <div
                    class="navigation__count"
                    *ngIf="0 | nnTotalUnreadGuestsCount | async as unreadGuestsCount">
                    {{ unreadGuestsCount }}
                </div>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|guests" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            *nnUserRoleOnly="'Employer'"
            routerLink="/account/vacancy-applications"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|vacancy_applications' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileGuest2"
                    svgIcon="sprite:icon-profileGuest2"></mat-icon>
                <div
                    class="navigation__count"
                    *ngIf="0 | nnTotalUnreadVacancyApplicationsCount | async as unreadApplicationsCount">
                    {{ unreadApplicationsCount }}
                </div>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|vacancy_applications" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            *ngIf="context.user.isWorker"
            routerLink="/account/recommendations"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|recommendations' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileRecs2"
                    svgIcon="sprite:icon-profileRecs2"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|recommendations" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            *ngIf="context.user.isEmployer"
            routerLink="/account/vacancy-management"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|vacancy' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileVacancy"
                    svgIcon="sprite:icon-profileVacancy"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|vacancy" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            routerLink="/account/profile"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|personal_data' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileUser"
                    svgIcon="sprite:icon-profileUser"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|personal_data" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            routerLink="/account/orders"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|purchases' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profilePayment"
                    svgIcon="sprite:icon-profilePayment"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|purchases" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            *ngIf="context.isReferralsEnabled"
            routerLink="/referrals"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|referrals' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileFriends"
                    svgIcon="sprite:icon-profileFriends"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|referrals" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            routerLink="/account/preferences"
            routerLinkActive="navigation__link_active"
            [routerLinkActiveOptions]="{exact: true}"
            [title]="'dashboard_page|settings' | localization">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileSettings"
                    svgIcon="sprite:icon-profileSettings"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|settings" | localization }}
            </span>
        </a>
        <a
            class="navigation__link"
            [title]="'dashboard_page|exit' | localization"
            (click)="logout()">
            <span class="navigation__media">
                <mat-icon
                    class="navigation__icon mat-icon_profileExit2"
                    svgIcon="sprite:icon-profileExit2"></mat-icon>
            </span>
            <span class="navigation__content">
                {{ "dashboard_page|exit" | localization }}
            </span>
        </a>
    </nav>
    <button
        class="postajob"
        *ngIf="context.user.isEmployer"
        type="button"
        routerLink="/register/employer-success">
        <div class="postajob__media">
            <img
                class="postajob__img"
                src="/assets/img/menu/postajob.svg"
                alt="Post a job"/>
        </div>
        <div class="postajob__text">
            {{ "dashboard_page|employer_post_job" | localization }}
        </div>
    </button>
</ng-container>
