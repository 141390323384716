import {Injectable} from '@angular/core';
import {CatalogType} from '../../../pages/catalog/routing/CatalogType';
import {WorkType} from '../../../business/catalog/core/api';
import {BehaviorSubject, Observable} from 'rxjs';
import {ActivationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';

export type Catalog = { catalogType: CatalogType, workType: WorkType, action: ('view' | 'search') };
const UNDEFINED_CATALOG: Catalog = {
    catalogType: CatalogType.Undefined,
    workType: WorkType.Undefined,
    action: undefined,
};

@Injectable({
    providedIn: 'root'
})
// todo: refactor
export class CatalogMenuService {
    public currentCatalog$: Observable<Catalog>;
    private currentCatalogSubject$: BehaviorSubject<Catalog>;

    constructor(router: Router) {
        this.currentCatalogSubject$ = new BehaviorSubject(UNDEFINED_CATALOG);
        this.currentCatalog$ = this.currentCatalogSubject$
            .asObservable()
            .pipe(distinctUntilChanged());

        router.events.pipe(
            filter(event => event instanceof ActivationEnd),
            filter((event: ActivationEnd) => event.snapshot.firstChild == null),
            map((event: ActivationEnd) => {
                const catalogMenu = event.snapshot.data.catalogMenu;
                const action = event.snapshot.data.action;

                if (!catalogMenu)
                    return undefined;

                return {...catalogMenu, action: action === 'view' ? action : 'search'};
            }),
            tap((catalog: Catalog) => this.currentCatalogSubject$.next(catalog || UNDEFINED_CATALOG))
        )
            .subscribe();
    }
}
