import {Component, inject, OnDestroy} from '@angular/core';
import {AlertsService} from '../../infrastructure/alerts/alerts.service';
import {Subject} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatIcon} from "@angular/material/icon";
import {LocalizationPipe} from "../../infrastructure/localization/localization.pipe";
import {AsyncPipe, NgIf} from "@angular/common";
import {AppTimersService} from "../../infrastructure/timers/app-timers.service";

@Component({
    selector: 'nn-layout-success-message',
    templateUrl: 'component.html',
    styleUrls: ['component.sass'],
    standalone: true,
    imports: [
        MatIcon,
        LocalizationPipe,
        AsyncPipe,
        NgIf
    ]
})
export class SuccessMessageComponent implements OnDestroy {
    private readonly appTimers = inject(AppTimersService);
    private readonly destroySubject$: Subject<void> = new Subject<void>();

    constructor(public alertsService: AlertsService) {
        alertsService.successMessage$.pipe(
            switchMap(() => this.appTimers.getTimer({dueTime: 4000, observeOnNgZone: true})),
            tap(() => alertsService.clearSuccessMessages()),
            takeUntil(this.destroySubject$),
        ).subscribe();
    }

    public clearMessage(): void {
        this.alertsService.clearSuccessMessages();
    }

    public ngOnDestroy(): void {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    }
}
