import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, Router, UrlTree} from '@angular/router';
import {IdentityService} from '../../../business/users/core/identity.service';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class RatesToBuyGuard implements CanActivate, CanActivateChild {
    constructor(
        private readonly identityService: IdentityService,
        private readonly router: Router) {}

    public canActivate(): Observable<true | UrlTree> {
        return this.identityService.stateChanges.pipe(
            take(1),
            map((authState) => !authState.user || this.router.createUrlTree(['/buy'])),
        );
    }

    public canActivateChild(): Observable<true | UrlTree> {
        return this.canActivate();
    }
}
